import type { StyleConfig } from '@chakra-ui/react'

export const InputSelect: StyleConfig = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    md: {
      field: {
        borderRadius: 'none',
      },
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    outline: {
      field: {
        color: 'black',
        borderColor: 'txtDarkGray',
        _focus: {
          borderColor: 'txtBlue',
        },
      },
    },
  },
  // default values for `size` and `variant`
  defaultProps: {},
}
