import type { ThemeOverride } from '@chakra-ui/react'
import { extendTheme, withDefaultVariant } from '@chakra-ui/react'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { Button } from './components/button'
import { Checkbox } from './components/checkbox'
import { Divider } from './components/divider'
import { InputSelect } from './components/input-select'
import { Link } from './components/link'
import { Menu } from './components/menu'
import { Tabs } from './components/tab-bar'
import { fonts } from './components/fonts'
import { switchTheme } from './components/switch'

const txtTheme: ThemeOverride = extendTheme(
  {
    breakpoints,
    colors,
    fonts,
    components: {
      Button,
      Menu,
      Input: { ...InputSelect },
      Select: { ...InputSelect },
      Checkbox,
      Tabs,
      Divider,
      Link,
      Switch: switchTheme,
    },
    styles: {
      global: {
        body: {
          backgroundColor: 'txtWhite',
        },
        html: {
          backgroundColor: 'txtWhite',
        },
      },
    },
  },

  withDefaultVariant({
    variant: 'txtBlack',
    components: ['Button', 'Tabs'],
  }),
  withDefaultVariant({
    variant: 'outline',
    components: ['Input', 'NumberInput', 'PinInput'],
  })
)

export default txtTheme
