import type { StyleConfig } from '@chakra-ui/react'

export const Link: StyleConfig = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    txtWhite: {
      color: 'txtWhite',
      _hover: {
        color: 'txtWhite',
      },
    },
    txtBlack: {
      color: 'txtBlack',
      _hover: {
        color: 'txtBlack',
      },
    },
    txtBlue: {
      color: 'txtBlue',
      _hover: {
        color: 'txtBlue',
      },
    },
    disabled: {
      color: 'txtMidGray',
      opacity: 0.5,
      _hover: {
        color: 'txtMidGray',
        textDecoration: 'none',
      },
    },
    comingSoon: {
      color: 'txtMidGray',
      opacity: 0.5,
      _hover: {
        color: 'txtMidGray',
        textDecoration: 'none',
        cursor: 'default',
      },
    },
    comingSoonDarker: {
      color: 'txtDarkerGray',
      opacity: 0.5,
      _hover: {
        color: 'txtDarkerGray',
        textDecoration: 'none',
        cursor: 'default',
      },
    },
  },
  // default values for `size` and `variant`
  defaultProps: {},
}
