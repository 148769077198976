import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys
)

const txtBlue = definePartsStyle({
  container: {},
  track: {
    bg: 'txtBlue',
    border: '1px solid #a9aaac',
    _checked: {
      bg: 'txtWhite',
    },
  },
  thumb: {
    bg: 'txtWhite',
    _checked: {
      bg: 'txtBlue',
    },
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const switchTheme: any = defineMultiStyleConfig({
  variants: { txtBlue },
})
