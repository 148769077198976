import type { StyleConfig } from '@chakra-ui/react'

export const Divider: StyleConfig = {
  // style object for base or default style
  baseStyle: {
    opacity: 100,
    borderColor: 'black',
    borderWidth: '1px',
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    lg: { borderWidth: '4px' },
    md: { borderWidth: '2px' },
    sm: { borderWidth: '1px' },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for `size` and `variant`
  defaultProps: {
    size: 'sm',
  },
}
