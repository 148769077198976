import type { StyleConfig } from '@chakra-ui/react'
import { defineStyle } from '@chakra-ui/react'
import { montserrat } from '../../fonts'

const baseStyle = defineStyle({
  fontFamily: montserrat.style.fontFamily,
  textTransform: 'uppercase',
  textDecoration: 'none',
  border: '1px solid black',
  borderRadius: 'full',
  rounded: 'full',
  w: 'full',
  maxW: ['full', '340px'],
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _focusVisible: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
    backgroundColor: 'txtLightGray',
    color: 'txtBlack',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
})

const variantTxtBlack = defineStyle(() => {
  return {
    backgroundColor: 'txtBlack',
    borderColor: 'txtWhite',
    color: 'txtWhite',
    _hover: {
      backgroundColor: 'txtWhite',
      borderColor: 'txtBlack',
      color: 'txtBlack',
    },
    _disabled: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
    },
  }
})

const variantTxtWhite = defineStyle(() => {
  return {
    backgroundColor: 'txtWhite',
    borderColor: 'txtBlack',
    color: 'txtBlack',
    _hover: {
      backgroundColor: 'txtBlack',
      borderColor: 'txtWhite',
      color: 'txtWhite',
    },
    _disabled: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
    },
    _active: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
      _hover: {
        backgroundColor: 'txtBlack',
        color: 'txtWhite',
      },
    },
  }
})
const variantTxtLighterGray = defineStyle(() => {
  return {
    backgroundColor: 'txtLighterGray',
    borderColor: 'txtBlack',
    color: 'txtBlack',
    _hover: {
      backgroundColor: 'txtBlack',
      borderColor: 'txtLighterGray',
      color: 'txtLighterGray',
    },
    _disabled: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
    },
    _active: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
      _hover: {
        backgroundColor: 'txtBlack',
        color: 'txtLighterGray',
      },
    },
  }
})

const variantTxtBlue = defineStyle(() => {
  return {
    backgroundColor: 'txtBlue',
    borderColor: 'txtWhite',
    color: 'txtWhite',
    _hover: {
      backgroundColor: 'txtWhite',
      borderColor: 'txtBlack',
      color: 'txtBlack',
    },
    _disabled: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
    },
  }
})

const variantTxtSignalLime = defineStyle(() => {
  return {
    backgroundColor: 'txtSignalLime',
    borderColor: 'txtBlack',
    color: 'txtBlack',
    _hover: {
      backgroundColor: 'txtWhite',
      borderColor: 'txtBlack',
      color: 'txtBlack',
      _disabled: {
        backgroundColor: 'txtWhite',
      },
    },
    _disabled: {
      backgroundColor: 'txtSignalLime',
    },
  }
})

const variantEdgy = defineStyle(() => {
  return {
    bg: 'txtBlue',
    color: 'txtWhite',
    rounded: 'none',
    border: 'none',
    px: 2,
    _hover: {
      backgroundColor: 'txtBlue',
      color: 'txtWhite',
    },
  }
})

const variantTxtBorderless = defineStyle(() => {
  return {
    backgroundColor: 'txtWhite',
    border: 'none',
    rounded: 'none',
    color: 'txtBlack',
    fontWeight: '400',
    _disabled: {
      backgroundColor: 'txtWhite',
      color: 'txtBlack',
    },
    _active: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
      _hover: {
        backgroundColor: 'txtBlack',
        color: 'txtWhite',
      },
    },
  }
})

const variantTxtFormEdit = defineStyle(() => {
  return {
    bg: 'none',
    color: 'txtBlack',
    textTransform: 'lowercase',
    maxW: 'max-content',
    border: 'none',
    _hover: {
      backgroundColor: 'txtBlack',
      color: 'txtWhite',
    },
    _disabled: {
      backgroundColor: 'txtWhite',
      color: 'txtBlack',
      _hover: {
        backgroundColor: 'txtWhite',
        borderColor: 'txtBlack',
        color: 'txtBlack',
      },
    },
  }
})

const variantTxtYellow = defineStyle(() => {
  return {
    backgroundColor: 'txtYellow',
    borderColor: 'txtBlack',
    color: 'txtBlack',
    _hover: {
      backgroundColor: 'txtWhite',
      borderColor: 'txtBlack',
      color: 'txtBlack',
    },
    _disabled: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
    },
  }
})

const variantTxtCoral = defineStyle(() => {
  return {
    backgroundColor: 'txtCoral',
    borderColor: 'txtBlack',
    color: 'txtBlack',
    _hover: {
      backgroundColor: 'txtWhite',
      borderColor: 'txtBlack',
      color: 'txtBlack',
    },
    _disabled: {
      backgroundColor: 'txtLightGray',
      color: 'txtBlack',
    },
  }
})

export const Button: StyleConfig = {
  // style object for base or default style
  baseStyle,
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    small: {
      fontSize: '1rem',
      py: 2,
      w: 'full',
      maxW: ['full', '16rem'],
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    txtBlack: variantTxtBlack,
    txtWhite: variantTxtWhite,
    txtBlue: variantTxtBlue,
    txtLighterGray: variantTxtLighterGray,
    txtSignalLime: variantTxtSignalLime,
    edgy: variantEdgy,
    txtBorderless: variantTxtBorderless,
    txtFormEdit: variantTxtFormEdit,
    txtYellow: variantTxtYellow,
    txtCoral: variantTxtCoral,
  },
  // default values for `size` and `variant`
  // defaultProps: {},
}
