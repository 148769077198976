import type { StyleConfig } from '@chakra-ui/react'
import { neueMachina } from '../../fonts'

export const Tabs: StyleConfig = {
  // style object for base or default style
  baseStyle: {
    tabpanel: {
      paddingX: '0px',
    },
    tabpanels: {
      borderTop: '2px solid',
      marginTop: '-3px',
    },
    tab: {
      fontFamily: neueMachina.style.fontFamily,
      _selected: {
        borderBottom: '6px solid',
        boxShadow: 'none',
        fontWeight: '600',
        borderColor: 'txtBlack',
      },
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    txtBlack: {
      tab: {
        color: 'black',
        _selected: {
          color: 'black',
        },
      },
    },
  },
  // default values for `size` and `variant`
  defaultProps: {},
}
