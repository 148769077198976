export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',

  // TxT specific colors
  // Do only add new colors in cooperation with the designer
  txtBlack: '#1a1a1a',
  txtWhite: '#ffffff',
  txtDarkGray: '#a9aaac',
  txtMidGray: '#c4c4c4',
  txtLightGray: '#e5e5e5',
  txtLighterGray: '#ebebe7',
  txtBlue: '#1b1bd2',
  txtPurple: '#a996df',
  txtDeutschRapPurple: '#452caa',
  txtLightPurple: '#6b55de',
  txtSignalLime: '#baff8f',
  txtYellow: '#EFF0B0',
  txtWarningRed: 'red',
  txtGreen: '#bccb8f',
  txtCoral: '#f09a97',
}
