import type { StyleConfig } from '@chakra-ui/react'
import { defineStyle } from '@chakra-ui/react'

const variantTxtWhite = defineStyle(() => {
  return {
    control: {
      borderRadius: 'none',
      borderColor: 'txtWhite',
      _focus: {
        border: 'none',
        ring: 2,
        ringColor: 'txtWhite',
      },
    },
  }
})

export const Checkbox: StyleConfig = {
  // style object for base or default style
  baseStyle: {
    control: {
      borderRadius: 'none',
      borderColor: 'txtBlack',
      _focus: {
        border: 'none',
        ring: 2,
        ringColor: 'txtBlack',
      },
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    xl: {
      label: {
        fontSize: 'sm',
        marginLeft: '1.5rem',
      },
      control: {
        width: '2rem',
        height: '2rem',
        borderWidth: '1px',
      },
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    txtWhite: variantTxtWhite,
  },
  // default values for `size` and `variant`
  defaultProps: {},
}
