import type { StyleConfig } from '@chakra-ui/react'

export const Menu: StyleConfig = {
  // style object for base or default style
  baseStyle: {
    list: {
      backgroundColor: 'white',
      borderColor: 'black',
      border: '1px',
      // borderTop: 0,
      // width: '350px',

      borderRadius: '0',
      // top: '20px',
      marginTop: '-10px',
    },
    button: {
      // backgroundColor: 'white',
      // borderColor: 'black',
      // border: '1px',
      //padding: '6px 10px 2px 10px',
      // width: '350px',
      borderRadius: '0',
      //_expanded: {borderRadius: '20px 20px 0 0 ', borderBottom: 0},
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for `size` and `variant`
  defaultProps: {},
}
